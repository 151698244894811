import * as React from "react"
import styled from "styled-components"
import MenuButton from "./button"
import Logo from "./icons/logo"
import MenuIcon from "./icons/MenuIcon"
import MobileMenu from "./MobileMenu"
import { ContentContainer } from "./styles"

const Wrapper = styled.header`
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 90;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: 0.5s ease opacity, 0.5s ease background-color;
  opacity: ${props => (props.hide ? 0 : 1)};
  background-color: ${props =>
    props.dark ? "rgba(242, 242, 242, 1)" : "transparent"};
  a {
    transition: 0.5s ease fill;
  }
`

const SubTitle = styled.div`
  left: 175px;
  top: 0px;
  position: absolute;
  z-index: 85;
  padding-top: 33px;
  padding-bottom: 30px;

  display: flex;
  justify-content: center;
`

const NavLink = styled.a`
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  cursor: pointer;
  position: relative;
  color: ${props => (props.dark ? "#3D3D3D" : "white")};
`

const Header = ({ setOpen, dark, hidden }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <>
      <SubTitle>
        <h5 className="small-p h-[42px] flex flex-col justify-end pb-[5px] md:hidden">
          Project Management.
        </h5>
      </SubTitle>
      <MobileMenu isOpen={menuOpen} setOpen={setMenuOpen} />
      <Wrapper dark={dark || menuOpen} hide={hidden}>
        <ContentContainer>
          <div className="flex justify-between items-center">
            <Logo dark={dark || menuOpen} />
            <nav className="flex items-center gap-6">
              <div className="md:hidden flex items-center gap-4">
                <NavLink dark={dark} href="#about" className="op-link">
                  About
                </NavLink>
                <NavLink dark={dark} href="#projects" className="op-link">
                  Projects
                </NavLink>
                <NavLink dark={dark} href="#services" className="op-link">
                  Services
                </NavLink>
                <NavLink dark={dark} href="#team" className="op-link">
                  Team
                </NavLink>
              </div>

              <MenuButton
                text="Get in touch"
                dark={dark || menuOpen}
                onClick={() => setOpen(true)}
              />
              <button
                className="hidden md:flex menu-toggle"
                onClick={() => setMenuOpen(!menuOpen)}
                aria-label={menuOpen ? "Close menu" : "Open menu"}
              >
                <MenuIcon isOpen={menuOpen} dark={dark || menuOpen} />
              </button>
            </nav>
          </div>
        </ContentContainer>
      </Wrapper>
    </>
  )
}

export default Header
