import React from "react"
import styled from "styled-components"
import { ContentContainer } from "./styles"

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(242, 242, 242, 1);
  z-index: 80;
  display: flex;
  flex-direction: column;
  pointer-events: ${props => (props.isOpen ? "auto" : "none")};
  justify-content: center;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: opacity 0.5s ease;
`

const NavItem = styled.a`
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #3d3d3d;
  cursor: pointer;
  position: relative;
  display: block;
  text-align: center;
`

const MobileMenu = ({ isOpen, setOpen }) => {
  return (
    <MenuOverlay isOpen={isOpen}>
      <ContentContainer>
        <div className="flex flex-col items-center gap-6">
          <NavItem
            href="#about"
            className="mobile-nav-link"
            onClick={() => setOpen(false)}
          >
            About
          </NavItem>
          <NavItem
            href="#projects"
            className="mobile-nav-link"
            onClick={() => setOpen(false)}
          >
            Projects
          </NavItem>
          <NavItem
            href="#services"
            className="mobile-nav-link"
            onClick={() => setOpen(false)}
          >
            Services
          </NavItem>
          <NavItem
            href="#team"
            className="mobile-nav-link"
            onClick={() => setOpen(false)}
          >
            Team
          </NavItem>
        </div>
      </ContentContainer>
    </MenuOverlay>
  )
}

export default MobileMenu
